import { backEndApi } from '@root/libs';
import { toQueryString } from '@root/utils';

const api = '/api/v1';
const sitePath = '/sites';
const siteAPIs = {
  getAll: (queryParams) =>
    backEndApi.get(
      `${api}${sitePath}${queryParams ? `?${toQueryString(queryParams)}` : ''}`,
    ),
  getSiteUsers: (siteId) => backEndApi.get(`${api}${sitePath}/${siteId}/users`),
  getSitesUsers: (siteIds) =>
    backEndApi.get(`${api}${sitePath}/users?siteIds=${siteIds}`),
  inviteUserToManySites: (body) =>
    backEndApi.post(`${api}${sitePath}/user/invite`, body),
  removeUserFromSite: (siteId, accountId) =>
    backEndApi.delete(`${api}${sitePath}/${siteId}/user/${accountId}`),
  getAllApplicationRoles: (siteId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/application`),
  getAllSiteApplicationRoles: (body) =>
    backEndApi.post(`${api}${sitePath}/sites/application`, body),
  getIssueFieldsFromAtlassian: (siteId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/fields/search`),
  resetUserPasswordFromSite: (siteId, accountId) =>
    backEndApi.put(
      `${api}${sitePath}/${siteId}/user/reset-password?accountId=${accountId}`,
    ),
  checkPermissionConfluence: (siteIds) =>
    backEndApi.get(`${api}${sitePath}/confluence-available?siteIds=${siteIds}`),
  checkProductPermissionConfluence: (siteId) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/confluence-available`),
  checkGlobalSharing: (siteIds) =>
    backEndApi.get(`${api}${sitePath}/checkGlobalSharing?siteIds=${siteIds}`),
  checkDeletedSite: ({ siteId }) =>
    backEndApi.get(`${api}${sitePath}/${siteId}/checkDeleted`),
  checkNewUpdateStore: (siteIds) =>
    backEndApi.get(
      `${api}${sitePath}/check-new-update-store?siteIds=${siteIds.join(',')}`,
    ),
  updateOrganizationId: (body) =>
    backEndApi.post(`${api}${sitePath}/update-organization-id`, body),
};
export default siteAPIs;
