import React from 'react';
import i18next from 'i18next';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { makeStyles, styled } from '@material-ui/styles';
import {
  ADDON_INSTALL_PRODUCT,
  ADDON_INSTALL_STATUS,
} from '@root/utils/constant';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import { AtlassianIcon } from '@root/components/common/icons';
import Lozenge from '@atlaskit/lozenge';

const useStyles = makeStyles(() => ({
  disable: {
    opacity: 0.5,
  },
  productBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  productLink: {
    display: 'flex',
    flexDirection: 'column',
  },
  licenseText: {
    fontSize: 10,
    fontWeight: 400,
    color: '#E5686F',
    whiteSpace: 'nowrap',
  },
  atlasLogo: {
    background: '#f4f5f7',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  sandboxIcon: {
    '& > span': {
      background: '#0065FF',
      borderRadius: 3,
    },
    '& path': {
      fill: '#fff',
    },
  },
  logoInfo: {
    position: 'absolute',
    bottom: -4,
    right: -4,
  },
  siteBox: {
    color: '#42526E',
    display: 'flex',
    alignItems: 'center',
  },
  labelBox: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  productUrl: {
    display: 'flex',
    gap: 8,
    fontWeight: '600',
    whiteSpace: 'nowrap',
    fontSize: 10,
  },
  productName: {
    display: 'flex',
    fontSize: 14,
    fontWeight: '500',
    gap: '5px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  planName: {
    fontSize: 12,
    color: '#6D6E6F',
  },
  manageAppLink: {
    color: '#0052CC',
    cursor: 'pointer',
    fontWeight: '500',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Link = styled('a')({
  color: '#0052CC',
  cursor: 'pointer',
  fontWeight: '500',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Item = styled('div')({
  color: '#42526E',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

/**
 *  ProductItem component renders an product's name with a icon of product.
 *
 * @param {Object} props - Component props.
 * @param {string} props.productName - The name of the product.
 * @param {boolean} props.isSandbox - The sandbox status of the product.
 * @param {string} props.typeKey - The type key of the product.
 * @param {string} props.planName - The plan name of the product.
 * @returns {JSX.Element} A JSX element displaying the product name with a icon of product.
 */
const ProductItem = ({
  productName,
  typeKey,
  planName,
  isSandbox = false,
  size = 'medium',
}) => {
  const classes = useStyles();
  return (
    <div className={classes.siteBox}>
      <div
        className={`${classes.atlasLogo} ${
          isSandbox ? classes.sandboxIcon : ''
        }`}
      >
        <AtlassianIcon typeKey={typeKey} size={size} />
      </div>
      <div className={classes.labelBox}>
        <span className={classes.productName}>
          {productName}
          {isSandbox && (
            <Lozenge appearance='inprogress'>
              {i18next.t('appInstallation.sandbox')}
            </Lozenge>
          )}
        </span>
        <span className={classes.planName}>{planName}</span>
      </div>
    </div>
  );
};

const CHECK_LICENSE_ADDON = [ADDON_INSTALL_STATUS.CONNECTED];
const isCheckLicenseAddon = (status) => CHECK_LICENSE_ADDON.includes(status);

/**
 *  SiteItem component renders an site's name with a link to manage app.
 *
 * @param {Object} props - Component props.
 * @param {string} props.siteName - The name of the site.
 * @param {string} props.productType - The product type of the site.
 * @param {string} props.installedStatus - The status of the installed site.
 * @returns {JSX.Element} A JSX element displaying the site name with a link to manage app.
 */
const SiteItem = ({
  siteName = '',
  productType = ADDON_INSTALL_PRODUCT.JIRA,
  installedStatus = '',
  isLicense = true,
}) => {
  const classes = useStyles();
  const manageAppLink =
    productType === ADDON_INSTALL_PRODUCT.JIRA
      ? `https://${siteName}.atlassian.net/plugins/servlet/upm`
      : `https://${siteName}.atlassian.net/wiki/plugins/servlet/upm`;

  const enableLicense = isCheckLicenseAddon(installedStatus);
  return (
    <div className={classes.productBox}>
      {!enableLicense || isLicense ? (
        <Link
          href={manageAppLink ?? ''}
          target='_blank'
          rel='noopener noreferrer'
        >
          {siteName}
        </Link>
      ) : (
        <>
          <WarningIcon size='large' primaryColor='#E5686F' />
          <div className={classes.productLink}>
            <Link
              href={manageAppLink ?? ''}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.disable}
            >
              {siteName}
            </Link>
            <span className={classes.licenseText}>
              {i18next.t('common.licenseOutdate')}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

/**
 * OrganizationItem component renders an organization's name with an icon.
 *
 * @param {Object} props - Component props.
 * @param {string} props.orgName - The name of the organization.
 * @returns {JSX.Element} A JSX element displaying the organization name with an icon.
 */
const OrganizationItem = ({ orgName }) => (
  <Item>
    <OfficeBuildingIcon size='small' />
    {orgName}
  </Item>
);

export { SiteItem, ProductItem, OrganizationItem };
