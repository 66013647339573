import React from 'react';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '@atlaskit/button';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import Notification from '@root/components/Notification';

const ModalInfo = (props) => {
  const { onSuccess = () => {}, isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const renewData = useSelector((state) => state?.app?.renewModalData);
  const updateOrgSuccess = () => {
    newRelicUtils.addPageAction(PAGE_ACTION.orgApiKey.cannel);
    setIsOpen('');
    if (renewData === 'security') {
      onSuccess();
    }
  };
  const renderCard = () => {
    if (isOpen === 'notification') return <Notification />;
    return '';
  };

  return (
    <ModalTransition>
      <Modal width='100vw' autoFocus='false' height='100%'>
        <ModalBody>{renderCard()}</ModalBody>
        <ModalFooter>
          <Button appearance='subtle' onClick={() => updateOrgSuccess()}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};

export default ModalInfo;
