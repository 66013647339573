import { backEndApi } from '@root/libs';

const api = '/api/v1';
const orgPath = '/organization';

const organizationAPIs = {
  // Return uniq organization id list
  getAllOrganization: () => backEndApi.get(`${api}${orgPath}`),
  getAllOrganizationsAdmin: () => backEndApi.get(`${api}${orgPath}/admin`),
  // Return all organization api keys
  getAllOrganizationAPIKeys: () => backEndApi.get(`${api}${orgPath}/apikeys`),
  getOrganizationAPIByOrgId: (orgId) =>
    backEndApi.get(`${api}${orgPath}/${orgId}/api-keys`),
  getAllDeletedOrganization: () => backEndApi.get(`${api}${orgPath}/deleted`),
  checkExistApikey: (data) =>
    backEndApi.post(`${api}${orgPath}/apikey/check`, data),
  createOrganizationApikey: (body) =>
    backEndApi.post(`${api}${orgPath}/apikey`, body),
  updateOrganizationApikey: (body) =>
    backEndApi.put(`${api}${orgPath}/apikey`, body),
  validOrganizationApikey: (body) =>
    backEndApi.post(`${api}${orgPath}/valid`, body),
  deleteOrganizationApikey: (orgId, orgApikeyId) =>
    backEndApi.delete(`${api}${orgPath}/${orgId}/id/${orgApikeyId}`),
  checkIsAvailableOrgApiKey: (body) =>
    backEndApi.post(`${api}${orgPath}/apikey/check-available`, body),
  checkApiKeysForOrgIds: (body) =>
    backEndApi.post(`${api}${orgPath}/user-organizations/check-apikey`, body),
  checkNewUserManagementForOrgIds: (body) =>
    backEndApi.post(`${api}${orgPath}/user-management/new-ui-check`, body),
};

export default organizationAPIs;
