import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import Select, { components } from '@atlaskit/select';
import i18next from 'i18next';
import { ADDON_INSTALL_PRODUCT } from '@root/utils';
import { ProductItem } from '../items';

const useStyles = makeStyles(() => ({
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    cursor: 'pointer',
    fontSize: 14,
  },
  optionItemDisabled: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    cursor: 'not-allowed',
    fontSize: 14,
  },
}));

const PRODUCT_OPTIONS = [
  {
    id: 0,
    value: 'all',
    key: 'all',
    label: i18next.t('common.all'),
  },
  {
    id: 1,
    value: ADDON_INSTALL_PRODUCT.JIRA_SOFTWARE,
    key: ADDON_INSTALL_PRODUCT.JIRA_SOFTWARE,
    label: <span>{i18next.t('common.atlas.jiraSoftware')}</span>,
  },
  {
    id: 2,
    value: ADDON_INSTALL_PRODUCT.JIRA_PRODUCT_DISCOVERY,
    key: ADDON_INSTALL_PRODUCT.JIRA_PRODUCT_DISCOVERY,
    label: <span>{i18next.t('common.atlas.jiraProductDiscovery')}</span>,
  },
  {
    id: 3,
    value: ADDON_INSTALL_PRODUCT.JIRA_CORE,
    key: ADDON_INSTALL_PRODUCT.JIRA_CORE,
    label: <span>{i18next.t('common.atlas.jiraWorkManagement')}</span>,
  },
  {
    id: 4,
    value: ADDON_INSTALL_PRODUCT.JIRA_SERVICEDESK,
    key: ADDON_INSTALL_PRODUCT.JIRA_SERVICEDESK,
    label: <span>{i18next.t('common.atlas.jiraServiceManagement')}</span>,
  },
  {
    id: 5,
    value: ADDON_INSTALL_PRODUCT.CONFLUENCE,
    key: ADDON_INSTALL_PRODUCT.CONFLUENCE,
    label: <span>{i18next.t('common.atlas.confluence')}</span>,
  },
];

const NoOptionMessageCustom = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.NoOptionsMessage {...props}>
    <span>{i18next.t('common.noDataAvailable')}</span>
  </components.NoOptionsMessage>
);

const MultiValueCustom = ({ data, ...props }) => {
  if (data.value === 'all') return <></>;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValue {...props}>
      <span>{data?.label}</span>
    </components.MultiValue>
  );
};

const OptionCustom = ({ data, isSelected, ...props }) => {
  const { label, key, isDisabled } = data;
  const classes = useStyles();
  return (
    <components.Option {...props}>
      {key === 'all' ? (
        <div className={classes.optionItem}>
          <Checkbox isChecked={isSelected} isDisabled={isDisabled} />
          <div>{label}</div>
        </div>
      ) : (
        <div
          className={
            isDisabled ? classes.optionItemDisabled : classes.optionItem
          }
        >
          <Checkbox isChecked={isSelected} isDisabled={isDisabled} />
          <ProductItem productName={label} typeKey={key} size='small' />
        </div>
      )}
    </components.Option>
  );
};

const ProductMultiSelect = ({
  options = PRODUCT_OPTIONS,
  values = PRODUCT_OPTIONS,
  onChange = () => {},
  ...props
}) => (
  <Select
    options={options}
    isSearchable={false}
    value={values}
    onChange={(items) => {
      const isAllSelected = items.some((item) => item.value === 'all');
      const isDeALlSelected =
        !isAllSelected && values.length === options.length;

      if (items?.length === 0 || isDeALlSelected) {
        onChange([options[1]]);
        return;
      }
      if (isAllSelected && values.length !== options.length) {
        onChange(options);
      } else if (!isAllSelected && items.length === options.length - 1) {
        onChange(options);
      } else {
        const validItems = items.filter((item) => item.value !== 'all');
        onChange(validItems);
      }
    }}
    components={{
      Option: OptionCustom,
      MultiValue: MultiValueCustom,
      NoOptionsMessage: NoOptionMessageCustom,
    }}
    isMulti
    menuPosition='fixed'
    hideSelectedOptions={false}
    closeMenuOnSelect={false}
    {...props}
  />
);

export { ProductMultiSelect, PRODUCT_OPTIONS };
