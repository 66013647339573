import React from 'react';
import { useTranslation } from 'react-i18next';


const maintenanceContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh', // Full viewport height
  textAlign: 'center',
};

const contentStyle = {
  marginTop: '20px', // Space between image and text
};

const imageStyle = {
  maxWidth: '100%',
  height: 'auto',
};

const MaintenancePage = () => {
  const { t } = useTranslation();
  return (
    <div style={maintenanceContainerStyle}>
      <img
        src="https://daccel-static.s3.ap-northeast-1.amazonaws.com/img/d_accel_yoko.png"
        alt="Maintenance"
        width="400"
        style={imageStyle}
      />
      <div style={contentStyle}>
      <p>{t('maintenanceMessage.title')}</p>
      <p>{t('maintenanceMessage.description')}</p>
      </div>
    </div>
  );
};

export default MaintenancePage;